<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator"
      class="tile is-parent pl-1">
      <article class="tile is-child box">
        <div class="columns is-flex is-align-items-center">
          <div class="column">
            <p class="title">Detail Info</p>
          </div>
          <div v-if="!hasError && showSavingStatus && !toastNotificationEnabled"
            class="column is-flex is-justify-content-flex-end is-align-items-center">
            <span v-if="saving"
              class="button is-loading is-white"
              style="height: 27px;" />
            <span v-else
              class="text has-text-success">
              <i class="mdi mdi-checkbox-marked-circle-outline mdi-18px" />
            </span>
            <span class="is-size-6"
              :class="saving ? 'has-text-warning' : 'has-text-success'">{{ savingStatus }}</span>
          </div>
        </div>

        <div class="field columns">
          <div
            class="column is-half">
            <label class="label">Name</label>
            <div class="control">
              <input class="input"
                type="text"
                v-model="companyDetails.name"
                :disabled="!$userInfo.isSystemAccount">
            </div>
          </div>
          <div
            class="column is-half">
            <label class="label">ABN</label>
            <div class="control">
              <input class="input"
                type="text"
                v-model="companyDetails.abn"
                :disabled="!$userInfo.isSystemAccount">
            </div>
          </div>
        </div>
        <div class="field columns">
          <div class="column is-half pb-0">
            <label class="label">Customer Since</label>
            <v-date-picker
              v-model="companyDetails.customerSince"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="customerSinceInputEvent"
              class="is-fullwidth"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field has-addons">
                  <div class="control is-expanded has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Expiry date"
                      :value="inputValue"
                      v-on="inputEvents"
                      :disabled="!$userInfo.isSystemAccount">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div
            class="column is-half pb-0">
            <label class="label">Registered ID</label>
            <div class="control">
              <input class="input"
                type="text"
                v-model="companyDetails.companyRegisteredId"
                :disabled="!$userInfo.isSystemAccount">
            </div>
          </div>
        </div>
        <hr>

        <div class="field columns">
          <div class="column is-half pt-0 mt-3"
            style="border-right: 2px solid #F5F5F5">
            <div class="mb-5">
              <label class="label">Country</label>
              <div class="control tooltip"
                data-tooltip="Country of Default Address">
                <div class="select is-fullwidth">
                  <select v-model="companyDetails.countryCode"
                    :disabled="!$userInfo.isSupportUser">
                    <option value="null"
                      disabled>Select</option>
                    <option v-for="(country, countryCode) in countries"
                      :key="countryCode"
                      :value="countryCode">
                      {{ country }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="mb-5">
              <label class="label">Time Zone</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="companyDetails.timezone"
                    :disabled="!companyDetails.countryCode || !$userInfo.isSupportUser">
                    <option value="null"
                      disabled>Select</option>
                    <option v-for="(timezone, index) in timezones"
                      :key="index"
                      :value="timezone">
                      {{ timezone }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="mb-5">
              <label class="label">Locale</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="companyDetails.locale"
                    :disabled="!companyDetails.countryCode || !$userInfo.isSupportUser">
                    <option value="null"
                      disabled>Select</option>
                    <option v-for="(locale, index) in locales"
                      :key="index"
                      :value="locale">
                      {{ locale }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-half pb-0">
            <div class="field">
              <company-asset-addresses
                ref="assetAddresses"
                :addresses="sortedAddresses"
                :country-code="companyDetails.countryCode"
                @removeAddress="removeAddress"
                @setDefault="setDefault"
                @addTmpAddress="addTmpAddress" />
            </div>
          </div>
        </div>
        <hr>

        <div class="field columns">
          <div class="column is-half">
            <company-asset-phones
              ref="assetPhones"
              :phones="sortedPhones"
              @removePhone="removePhone"
              @setDefault="setDefault"
              @addTmpPhone="addTmpPhone" />
          </div>

          <div class="column is-half">
            <company-asset-emails
              ref="assetEmails"
              :emails="sortedEmails"
              @removeEmail="removeEmail"
              @setDefault="setDefault"
              @addTmpEmail="addTmpEmail" />
          </div>
        </div>

        <div class="field columns mb-2">
          <div class="column is-half">
            <div class="is-flex is-justify-content-space-between">
              <label class="label">Contact Person</label>
              <button class="button is-small is-primary is-light"
                @click="addContact"
                :disabled="!$userInfo.isSystemAccount">
                <i class="icon mdi mdi-plus mdi-24px" />
              </button>
            </div>
            <div v-if="companyDetails && companyDetails.contacts?.length > 0">
              <div class="field columns mb-0">
                <div class="column is-half">
                  <label class="label has-text-weight-normal">First Name</label>
                </div>
                <div class="column is-half">
                  <label class="label has-text-weight-normal">Last Name</label>
                </div>
              </div>

              <div v-for="(contact, index) in companyDetails.contacts"
                :key="index"
                class="field columns is-flex is-align-items-center">
                <div class="column is-half">
                  <div class="control">
                    <input class="input"
                      type="text"
                      placeholder="First Name"
                      v-model="contact.firstName"
                      :disabled="!$userInfo.isSystemAccount">
                  </div>
                </div>
                <div class="field has-addons column is-half">
                  <div class="control is-expanded">
                    <input class="input"
                      type="text"
                      placeholder="Last Name"
                      v-model="contact.lastName"
                      :disabled="!$userInfo.isSystemAccount">
                  </div>
                  <div class="control">
                    <button class="button has-text-gold is-outlined"
                      @click="setDefault(contact, 'contacts')"
                      :disabled="!$userInfo.isSystemAccount">
                      <span class="icon">
                        <i class="mdi mdi-24px"
                          :class="[contact.isDefault ? 'mdi-star': 'mdi-star-outline']" />
                      </span>
                    </button>
                  </div>
                  <div class="control">
                    <button class="button has-text-danger"
                      @click="removeContact(contact)"
                      :disabled="!$userInfo.isSystemAccount">
                      <span class="icon">
                        <i class="mdi mdi-minus mdi-24px" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else
              class="label has-text-weight-normal ml-1">No Contacts</div>
          </div>
        </div>
      </article>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import { AddressModel, ContactModel, EmailModel, PhoneModel } from '@/classes/viewmodels'
import { Country, CountryCallingCodes, Locale, MalaysiaStates, StateAreaCodes, Timezone } from '@/enums'
import CompanyAssetAddresses from './assets/CompanyAssetAddresses'
import CompanyAssetEmails from './assets/CompanyAssetEmails'
import CompanyAssetPhones from './assets/CompanyAssetPhones'
import ContactService from '../contact/ContactService'
import StoreMixin from './storeMixin'

export default {
  name: 'CompanyDetailSetting',
  components: {
    CompanyAssetAddresses,
    CompanyAssetEmails,
    CompanyAssetPhones
  },
  mixins: [StoreMixin],
  data() {
    return {
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      companyDetails: {},
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['DD/MM/YYYY'],
        data: ['DD/MM/YYYY'],
        dayPopover: null
      },
      newContacts: []
    }
  },
  computed: {
    countries() {
      return Country
    },
    locales() {
      return this.companyDetails.countryCode ? Locale[this.companyDetails.countryCode] : []
    },
    mappingMalaysiaStates() {
      return MalaysiaStates
    },
    mappingStateAreaCodes() {
      return { ...StateAreaCodes.AUStateAreaCodes, ...StateAreaCodes.MYStateAreaCodes }
    },
    mappingCountryCallingCodes() {
      return CountryCallingCodes
    },
    sortedAddresses() {
      if (this.companyDetails && this.companyDetails.assetAddresses) {
        return [...this.companyDetails.assetAddresses].sort((a, b) => b.isDefault - a.isDefault)
      }
    },
    sortedEmails() {
      if (this.companyDetails && this.companyDetails.assetEmails) {
        return [...this.companyDetails.assetEmails].sort((a, b) => b.isDefault - a.isDefault)
      }
    },
    sortedPhones() {
      if (this.companyDetails && this.companyDetails.assetPhones) {
        return [...this.companyDetails.assetPhones].sort((a, b) => b.isDefault - a.isDefault)
      }
    },
    timezones() {
      return this.companyDetails.countryCode ? Timezone[this.companyDetails.countryCode] : []
    },
    validateError() {
      if (this.$refs.assetPhones !== undefined && this.$refs.assetEmails !== undefined &&
        this.$refs.assetAddresses !== undefined) {
        return this.$refs.assetPhones.$v.phones.$error || this.$refs.assetEmails.$v.emails.$error ||
          this.$refs.assetAddresses.$v.addresses.$error
      }
      return false
    }
  },
  watch: {
    companyDetails: {
      handler: function(val) {
        this.saveSnapshot({ entity: _cloneDeep(val) })
        this.autoUpdate(val)
      },
      deep: true
    },

    'companyDetails.countryCode': {
      handler: async function(newVal, oldVal) {
        if (oldVal && newVal && oldVal !== newVal) {
          this.companyDetails.assetAddresses = []
          this.companyDetails.timezones = []
          this.companyDetails.timeZoneId = null
          this.companyDetails.timezone = null
          this.companyDetails.locale = null
        }
      }
    },

    'companyDetails.assetAddresses': {
      handler: async function(val) {
        if (!val.length > 0) return

        for (let address of val) {
          if (address.stateText in this.mappingMalaysiaStates) {
            address.stateCode = this.mappingMalaysiaStates[address.stateText]
          }

          if (address.stateCode in this.mappingStateAreaCodes) {
            address.stateAreaCode = this.mappingStateAreaCodes[address.stateCode]
          }

          if (address.countryCode in this.mappingCountryCallingCodes) {
            address.countryCallingCode = this.mappingCountryCallingCodes[address.countryCode]
          }
        }
      },
      deep: true
    }
  },
  async created() {
    this.$showSpinner()
    this.initializeAutoUpdate()
    this.companyDetails = _cloneDeep(await this.getStoreItem({ serviceFunction: 'getCompanyDetails' }))
    this.$hideSpinner()
  },
  methods: {
    async addContact() {
      const newContact = await ContactService.getNewEntity()
      this.newContacts.push(newContact)
      this.companyDetails.contacts.push(new ContactModel(newContact.id, newContact.firstName, newContact.lastName))
    },
    addTmpAddress() {
      let newAddress = new AddressModel(this.companyDetails.companyId)
      if (this.companyDetails.assetAddresses.length == 0) {
        newAddress.isDefault = true
      }
      this.companyDetails.assetAddresses.push(newAddress)
    },
    addTmpEmail() {
      let newEmail = new EmailModel()
      if (this.companyDetails.assetEmails.length == 0) {
        newEmail.isDefault = true
      }
      this.companyDetails.assetEmails.push(newEmail)
    },
    addTmpPhone() {
      let newPhone = new PhoneModel()
      if (this.companyDetails.assetPhones.length == 0) {
        newPhone.isDefault = true
      }
      this.companyDetails.assetPhones.push(newPhone)
    },
    customerSinceInputEvent(date){
      const dateTime = new Date(date)
      dateTime.setHours(0, 0, 0, 0)
      this.companyDetails.customerSince = dateTime.toISOString()
    },
    disableAutoUpdate() {
      if (typeof this.autoUpdate === 'function'
        && typeof this.autoUpdate.cancel === 'function') {
        this.autoUpdate.cancel()
      }
      this.autoUpdate = () => {}
    },
    initializeAutoUpdate() {
      this.autoUpdate = _debounce(async (value) => {
        this.snapshotDiff && await this.updateCompanyDetails(value)
      }, this.debounceDelay)
    },
    removeAddress(address) {
      this.companyDetails.assetAddresses = this.companyDetails.assetAddresses.filter(a => a !== address)
    },
    removeContact(contact) {
      this.companyDetails.contacts = this.companyDetails.contacts.filter(c => c !== contact)
    },
    removeEmail(email) {
      this.companyDetails.assetEmails = this.companyDetails.assetEmails.filter(e => e !== email)
    },
    removePhone(phone) {
      this.companyDetails.assetPhones = this.companyDetails.assetPhones.filter(p => p !== phone)
    },
    setDefault(asset, assetType) {
      this.companyDetails[assetType].forEach(a => a.isDefault = a == asset)
    },
    async updateContacts() {
      for (const newContact of this.newContacts) {
        const contact = this.companyDetails.contacts.find(c => c.contactId == newContact.id)
        newContact.firstName = contact.firstName
        newContact.lastName = contact.lastName
        await ContactService.postContact(newContact)
      }
    },
    async updateCompanyDetails(companyDetails) {
      if (this.validateError) return
      if (this.validateCountrySectionError()) return
      if (!this.snapshotDiff) return

      if (this.newContacts.length > 0) await this.updateContacts()

      companyDetails.deepDiff = JSON.stringify(this.snapshotDiff)
      this.companyDetails = _cloneDeep(await this.editStoreItem({
        serviceFunction: 'updateCompanyDetails', entity: companyDetails }))

      if (this.toastNotificationEnabled) {
        this.openToastNotification()
      }
    },
    validateCountrySectionError() {
      if (this.companyDetails.assetAddresses.length == 0 ||
          this.companyDetails.timezone == null ||
          this.companyDetails.locale == null) {
        this.$notification.error('Company Details', 'Require timezone, locale and address')
        return true
      }

      const [defaultAddress] = this.companyDetails.assetAddresses.filter(address => address.isDefault)
      if (defaultAddress.countryCode !== this.companyDetails.countryCode) {
        this.$notification.error('Company Details', 'Default address country must match the selected country.')
        return true
      }
      return false
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.disableAutoUpdate()
    this.toastNotificationEnabled = true
    await this.updateCompanyDetails(this.companyDetails)
    this.clearSnapshots()
    this.toastNotificationEnabled = false
    next()
  }
}
</script>

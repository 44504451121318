<template>
  <div>
    <div class="is-flex is-align-items-center is-justify-content-space-between">
      <label class="label">Addresses</label>
      <button v-show="addresses.length < 3"
        class="button is-small is-primary is-light"
        @click="addTmpAddress"
        :disabled="!countryCode || !$userInfo.isSupportUser">
        <i class="icon mdi mdi-plus mdi-24px" />
      </button>
    </div>
    <div v-for="(address, index) in addresses"
      :key="index"
      class="pb-4">
      <div class="field has-addons">
        <p class="control">
          <span class="select">
            <select v-model="address.type"
              :disabled="!$userInfo.isSupportUser"
              :class="{ 'is-invalid' : !$v.addresses.types.uniqueAddressType }"
              v-tippy="{ content: 'Duplicate Type', theme: 'danger', enabled: !$v.addresses.types.uniqueAddressType }">
              <option v-for="(type, typeIndex) in addressTypes"
                :key="typeIndex"
                :value="typeIndex">
                {{ type }}
              </option>
            </select>
          </span>
        </p>
        <p class="control is-expanded">
          <google-autocomplete v-if="googleApi.mapStatus"
            :disabled="!$userInfo.isSupportUser"
            :bounds="googleApi.bounds"
            :id="'address' + index"
            :address="address"
            :v="$v.addresses.$each[index]"
            :show-address3="true"
            :country-code="googleMapAutoCompleteCountryCode" />
        </p>
        <p class="control">
          <button class="button has-text-gold is-outlined"
            @click="setDefault(address)"
            :disabled="(addresses.length == 1 && address.isDefault) || !$userInfo.isSupportUser">
            <span class="icon">
              <i class="mdi mdi-24px"
                :class="[address.isDefault ? 'mdi-star': 'mdi-star-outline']" />
            </span>
          </button>
        </p>
        <p class="control">
          <button class="button has-text-danger"
            @click="removeAddress(address)"
            :disabled="(addresses.length == 1 || address.isDefault) || !$userInfo.isSupportUser">
            <span class="icon">
              <i class="mdi mdi-minus mdi-24px" />
            </span>
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import addressesValidation from '@/assets/validateForms/Address'
import GoogleAutocomplete from '@/components/Address/GoogleAutocomplete'
import { AddressTypes } from '@/enums'

export default {
  name: 'CompanyAssetAddresses',
  components: {
    GoogleAutocomplete
  },
  props: {
    addresses: {
      type: Array,
      default: () => []
    },
    countryCode: {
      type: String | null,
      default: undefined
    }
  },

  data() {
    return {
      googleApi: {
        mapUrl: 'https://maps.googleapis.com/maps/api/js',
        mapKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        mapLibraries: 'places',
        mapCallback: 'initMap',
        mapStatus: null,
        bounds: null
      }
    }
  },

  computed: {
    addressTypes() {
      return AddressTypes
    },
    googleMapAutoCompleteCountryCode() {
      if (this.countryCode) {
        return this.countryCode
      }
      return this.$company.info.countryCode
    }
  },

  mounted() {
    this.$v.$touch()
  },

  created() {
    this.initGoogleMapApi()
  },

  methods: {
    addTmpAddress() {
      this.$emit('addTmpAddress')
    },
    initGoogleMapApi() {
      const vm = this
      window.initMap = function() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            const geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            const circle = new window.google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy
            })

            vm.googleApi.bounds = circle.getBounds()
          })
        }
        vm.googleApi.mapStatus = 'AddressDetail'
      }

      if (!window.google) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src =
          `${this.googleApi.mapUrl}?key=${this.googleApi.mapKey}&libraries=${this.googleApi.mapLibraries}&callback=${this.googleApi.mapCallback}`
        document.body.appendChild(script)
      } else {
        window.initMap()
      }
    },
    removeAddress(assetAddress) {
      this.$emit('removeAddress', assetAddress)
    },
    setDefault(assetAddress) {
      this.$emit('setDefault', assetAddress, 'assetAddresses')
    }
  },

  validations: {
    ...addressesValidation
  }
}
</script>

<style lang="scss" scoped>
.is-invalid {
  background-color: pink !important;
}
</style>

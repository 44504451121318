<template>
  <div>
    <div class="is-flex is-justify-content-space-between">
      <label class="label">Emails</label>
      <button v-show="emails.length < 2"
        class="button is-small is-primary is-light"
        @click="addTmpEmail"
        :disabled="!$userInfo.isSystemAccount">
        <i class="icon mdi mdi-plus mdi-24px" />
      </button>
    </div>
    <div v-for="(assetEmail, index) in emails"
      :key="index"
      class="field has-addons">
      <div class="control">
        <span class="select">
          <select :disabled="!$userInfo.isSystemAccount"
            v-model="assetEmail.type"
            :class="{ 'is-invalid' : !$v.emails.types.uniqueEmailType }"
            v-tippy="{ content: 'Duplicate Type', theme: 'danger', enabled: !$v.emails.types.uniqueEmailType }">
            <option v-for="(type, typeIndex) in emailTypes"
              :key="typeIndex"
              :value="type.toString()"
              @change="$v.emails.types.touch()">
              {{ typeIndex }}
            </option>
          </select>
        </span>
      </div>
      <div class="control is-expanded">
        <input class="input"
          type="text"
          v-model="assetEmail.address"
          @input="$v.emails.$each[index].address.$touch()"
          :disabled="!$userInfo.isSystemAccount">
        <span class="help is-danger"
          v-if="!$v.emails.$each[index].address.required">Email is required</span>
        <span class="help is-danger"
          v-if="!$v.emails.$each[index].address.email">Please enter a valid email</span>
      </div>

      <p class="control">
        <button class="button has-text-gold is-outlined"
          :title="[assetEmail.isDefault ? 'Default Email' : '']"
          :disabled="!$userInfo.isSystemAccount || (emails.length == 1 && assetEmail.isDefault)"
          @click="setDefault(assetEmail)">
          <span class="icon">
            <i class="mdi mdi-24px"
              :class="[assetEmail.isDefault ? 'mdi-star': 'mdi-star-outline']" />
          </span>
        </button>
      </p>
      <p class="control">
        <button class="button has-text-danger"
          @click="removeEmail(assetEmail)"
          :disabled="!$userInfo.isSystemAccount || assetEmail.isDefault">
          <span class="icon">
            <i class="mdi mdi-minus mdi-24px" />
          </span>
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import emailsValidation from '@/assets/validateForms/Emails'
import { EmailTypes } from '@/enums'

export default {
  name: 'CompanyAssetEmails',

  props: {
    emails: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    emailTypes() {
      return EmailTypes
    }
  },

  mounted() {
    this.$v.$touch()
  },

  methods: {
    addTmpEmail() {
      this.$emit('addTmpEmail')
    },
    removeEmail(assetEmail) {
      this.$emit('removeEmail', assetEmail)
    },
    setDefault(assetEmail) {
      this.$emit('setDefault', assetEmail, 'assetEmails')
    }
  },

  validations: {
    ...emailsValidation
  }
}
</script>

<style lang="scss" scoped>
.is-invalid {
  background-color: pink !important;
}
</style>